body{
	margin: 0;
    background-color: #1B1B1B;
}
p{
	color: #FFE97F;
}
a:visited{
    color: #BF00FF;
}
a:link{
    color: #FFE97F;
}
.logo{
    width: 50px;
}